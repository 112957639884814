import React, { useState, useEffect } from 'react';
import './Home.css';
import './HomeStyle.module.css';
import './Spinner.scss'; // Імпорт стилів для анімації
import Sidebar from '../Sidebar/Sidebar';
import axios from 'axios';
import BASE_URL from '../config';
import { useNavigate } from 'react-router-dom';
import './Spinner.scss';
const Generation = () => {
  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState('Category 1');
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${BASE_URL}/api/user/prompts`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const fetchedVideos = response.data.map((video) => ({
          id: video.id,
          title: video.title || 'nill',
          videoUrl: video.video || null,
          videoText: video.text || 'nill',
          thumbnail: video.thumbnail_url || 'nill',
          views: video.views || 'nill',
          time_ago: video.time_ago || 'nill',
          category: video.category || 'Category 1',
          status: video.status || 'pending',
        }));

        setVideos(fetchedVideos);
      } catch (error) {
        console.error('Error fetching videos:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchVideos();
  }, []);

  const checkVideoStatus = async (videoId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${BASE_URL}/api/check-video-status/${videoId}/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(`Error checking video status for ${videoId}:`, error);
      return null;
    }
  };

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const updatedVideos = await Promise.all(
        videos.map(async (video) => {
          if (video.status === 'pending') {
            const statusData = await checkVideoStatus(video.id);
            if (statusData && statusData.status !== 'pending') {
              return {
                ...video,
                status: statusData.status,
                videoUrl: statusData.video_url || null,
              };
            }
          }
          return video;
        })
      );
      setVideos(updatedVideos);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [videos]);

  const filteredVideos = videos.filter((video) => video.category === selectedCategory);

  return (
    <div className="generation-container" style={{ background: 'linear-gradient(rgb(74, 222, 155) 0%, rgb(42 126 88) 100%)' }}>
      <Sidebar />
      <main className="main-content">
        <div className="category-selector">
          <button
            className={selectedCategory === 'Category 1' ? 'active' : ''}
            onClick={() => setSelectedCategory('Category 1')}
          >
            My Generations
          </button>
          <button
            className={selectedCategory === 'Category 2' ? 'active' : ''}
            onClick={() => setSelectedCategory('Category 2')}
          >
            Popular
          </button>
        </div>

        {isLoading ? (
          <div className="loading-spinner">
            <div className="outer">
              <div className="inner">
                <div className="small"></div>
                <div className="small"></div>
                <div className="small"></div>
                <div className="small"></div>
              </div>
              <div className="big">
                <div className="small"></div>
              </div>
              <div className="big">
                <div className="small"></div>
              </div>
            </div>
            <p>Loading your videos...</p>
          </div>
        ) : (
          <div className="video-slider-vertical">
            {filteredVideos.length > 0 ? (
              <div className="slider-container">
                {filteredVideos.map((video) => (
                  <div key={video.id} className="video-card-vertical">
                    {video.status === 'pending' ? (
                     // {video.status === video.status ? (
                      <div className="video-loading">
                        <div className="outer">
                          <div className="inner">
                            <div className="small"></div>
                            <div className="small"></div>
                            <div className="small"></div>
                            <div className="small"></div>
                          </div>
                          <div className="big">
                            <div className="small"></div>
                          </div>
                          <div className="big">
                            <div className="small"></div>
                          </div>
                        </div>
                        {/*<p>🎥 Your video is being generated. Please wait a moment!</p>*/}
                      </div>
                    ) : (
                      <video
                        id={`video-${video.id}`}
                        controls
                        width="100%"
                        height="auto"
                        poster={`${BASE_URL}${video.thumbnail}`}
                        className="video-element"
                      >
                        <source src={`${BASE_URL}${video.videoUrl}`} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    )}
                    <div className="video-info">
                      <h3>{video.videoText}</h3>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p className="no-videos">No videos found</p>
            )}
          </div>
        )}
      </main>
    </div>
  );
};

export default Generation;
