import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './components/AuthContext'; // Імпорт AuthProvider
import Login from './components/Login/Login';
import Register from './components/Register/Register';
import User from './components/User';
import VideoGenerationPage from './components/VideoGenerationPage';
import Test from './components/VideoGenerationPageTest';
import Ask from './components/ask/Ask';
import Home from './components/home/Home';
import About from './components/About/About';
import { GoogleOAuthProvider } from "@react-oauth/google"

const App = () => {
  return (
    <AuthProvider> {/* Обгортаємо додаток у AuthProvider */}
      <GoogleOAuthProvider clientId='372972581140-1s084rmms362e0p58b4k3miusfgnt4rm.apps.googleusercontent.com'>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/user" element={<User />} />
          <Route path="/generation" element={<VideoGenerationPage />} />
          <Route path="/test" element={<Test />} />
          <Route path="/ask" element={<Ask />} />
          <Route path="/home" element={<Home />} />
          <Route path="/" element={<About />} />
        </Routes>
      </Router>
      </GoogleOAuthProvider>
    </AuthProvider>
  );
};

export default App;
