import React, { useEffect } from 'react';
import { Form, Input, Button } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { GoogleOutlined } from '@ant-design/icons';
import { useAuth } from '../AuthContext';
import './Login.css';
import logoImage from '../img/mahaon.png'; // Імпортуйте зображення тут
import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
const Login = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    // Видалення токена з localStorage
    localStorage.removeItem('token');
    console.log('Token removed from localStorage');
  }, []);

const onGoogleLoginSuccess = async (response) => {
    console.log("Google token received:", response.credential);

    try {
        console.log("Sending Google token to backend...");
        const backendResponse = await fetch('https://bec.agentai.space/api/google-login/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token: response.credential }),
        });
        console.log("Sended Google token to backend...");
        console.log(backendResponse)
        if (backendResponse.ok) {
            const data = await backendResponse.json();
            console.log("Backend response for google-login:", data);

            // Перевірка, чи токени присутні в відповіді
            if (data.access_token && data.refresh_token) {
                // Збереження токенів у localStorage
                localStorage.setItem('token', data.access_token);
                localStorage.setItem('refreshToken', data.refresh_token);
                console.log("Tokens saved to localStorage.");
                console.log("Tokens saved to localStorage.");
                navigate('/home');
            } else {
                console.error("Access or Refresh token missing in backend response:", data);
            }
        } else {
            const error = await backendResponse.json();
            console.error("Google login failed:", error);
        }
    } catch (error) {
        console.error("Error during Google login process:", error);
    }
};

  const onFinish = async (values) => {
    console.log(values);
    try {
      await login(values); // Логін через username/password
      navigate('/home');
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  return (
    <div className="login-container">
      <div className="login-form-container">
        <div className="butterfly butterfly-1"></div>
        <div className="butterfly butterfly-2"></div>
        <img src={logoImage} alt="MAHAON" className="logot" />
        <h2>Login</h2>
        <Form
          form={form}
          name="login"
          className="login-form"
          onFinish={onFinish}
          initialValues={{ remember: true }}
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input placeholder="Username" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>

          <Link to="/forgot-password" className="forgot-password">
            Forgot Password?
          </Link>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-button">
              Sign In
            </Button>
          </Form.Item>
        </Form>

        <div className="social-login">
          <span>or continue with</span>
          <div className="social-buttons">
          <GoogleLogin
            clientId="372972581140-1s084rmms362e0p58b4k3miusfgnt4rm.apps.googleusercontent.com"
            onSuccess={onGoogleLoginSuccess}
            redirectUri="https://agentai.space"
          />
          </div>
        </div>

        <div className="register-link">
          Don't have an account yet? <Link to="/register">Register for free</Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
