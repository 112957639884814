import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { UserOutlined, MailOutlined, AudioOutlined } from '@ant-design/icons';
import { Card, Typography, Button, Input, message, List } from 'antd';
import { useNavigate } from 'react-router-dom';  // Імпорт хука useNavigate для редіректу
// import './styles.css'; // Підключаємо CSS-стилі
// import './config'; // Імпортуємо API URL
import BASE_URL from './config'; // Імпортуємо домен

const { Title, Text } = Typography;
const { TextArea } = Input;

const UserInfo = () => {
    const [userInfo, setUserInfo] = useState(null);
    const [transcript, setTranscript] = useState('');
    const [recognition, setRecognition] = useState(null);
    const [isRecognizing, setIsRecognizing] = useState(false);
    const [prompts, setPrompts] = useState([]);
    const navigate = useNavigate();  // Використовуємо useNavigate для редіректу

    useEffect(() => {
        const token = localStorage.getItem('token');

        // Перевірка наявності токена і редірект, якщо його немає
        if (!token) {
            // message.error('You need to log in first.');
            navigate('/login');  // Редірект на сторінку входу
            return;
        }

        // Запит інформації про користувача
        axios.get(`${BASE_URL}/api/user/`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            setUserInfo(response.data);
        }).catch(error => {
            console.error('Error fetching user info:', error);
            message.error('Failed to load user information.');
        });

        // Запит промптів користувача
        axios.get(`${BASE_URL}/api/user/prompts/`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            setPrompts(response.data);
        }).catch(error => {
            console.error('Error fetching prompts:', error);
            message.error('Failed to load prompts.');
        });

        // Ініціалізація розпізнавання мови
        if ('webkitSpeechRecognition' in window || 'SpeechRecognition' in window) {
            const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
            const recognitionInstance = new SpeechRecognition();
            recognitionInstance.continuous = true;
            recognitionInstance.interimResults = false;
            recognitionInstance.lang = 'uk-UA';
            setRecognition(recognitionInstance);
        } else {
            console.error('Speech Recognition API is not supported in this browser.');
            message.error('Speech Recognition is not supported in your browser.');
        }
    }, [navigate]);  // Додаємо navigate як залежність

    const startRecognition = () => {
        if (recognition && !isRecognizing) {
            recognition.start();
            setIsRecognizing(true);
            recognition.onresult = (event) => {
                const speechToText = Array.from(event.results)
                    .map(result => result[0].transcript)
                    .join('');
                setTranscript(speechToText);
            };
            recognition.onend = () => setIsRecognizing(false);
        }
    };

    const stopRecognition = () => {
        if (recognition && isRecognizing) {
            recognition.stop();
            setIsRecognizing(false);
        }
    };

    const sendPromptToBackend = async (promptText, userId) => {
        try {
            const response = await axios.post(`${BASE_URL}/api/save-prompt/`, {
                text: promptText,
                user_id: userId
            });
            if (response.status === 201) {
                message.success('Prompt saved successfully!');
                const promptsResponse = await axios.get(`${BASE_URL}/api/user/prompts/`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                });
                setPrompts(promptsResponse.data);
            }
        } catch (error) {
            console.error('Error saving prompt:', error);
            message.error('Error saving prompt.');
        }
    };

    const handleSendClick = () => {
        if (transcript.trim()) {
            sendPromptToBackend(transcript, userInfo.id);
            setTranscript(''); // Очищаємо поле після надсилання
        } else {
            message.error('There is no text to send.');
        }
    };

    if (!userInfo) {
        return <p>Loading user info...</p>;  // Відображаємо повідомлення, поки дані завантажуються
    }

    return (
        <div className="container">
            <Card className="user-card" cover={<UserOutlined style={{ fontSize: '100px', color: '#1890ff' }} />}>
                <Title level={3}>User Information</Title>
                <div className="user-info">
                    <Text strong>
                        <UserOutlined style={{ marginRight: '8px' }} />
                        Username: {userInfo.username}
                    </Text>
                    <br />
                    <Text strong>
                        <MailOutlined style={{ marginRight: '8px' }} />
                        Email: {userInfo.email}
                    </Text>
                </div>
                <div className="actions">
                    <Button
                        type="primary"
                        icon={<AudioOutlined />}
                        onClick={startRecognition}
                        disabled={isRecognizing}
                    >
                        Start Speaking
                    </Button>
                    <Button onClick={stopRecognition} disabled={!isRecognizing}>
                        Stop
                    </Button>
                </div>
                <div className="text-area">
                    <TextArea
                        rows={4}
                        value={transcript}
                        onChange={(e) => setTranscript(e.target.value)}
                        placeholder="Your speech will be converted to text here..."
                    />
                </div>
                <div className="actions">
                    <Button type="primary" onClick={handleSendClick}>
                        Send
                    </Button>
                </div>
            </Card>
            <div className="prompts-container">
                <Title level={4}>Your Prompts</Title>
                <List
                    itemLayout="vertical"
                    dataSource={prompts}
                    renderItem={prompt => (
                        <List.Item key={prompt.id} className="prompt-card">
                            <Card title={prompt.text}>
                                <p>Status: {prompt.status}</p>
                                {prompt.video && (
                                    <div>
                                        <p>Video:</p>
                                        <video
                                            width="200px"
                                            controls
                                            style={{ maxWidth: '100%', height: 'auto', objectFit: 'contain' }}
                                        >
                                            <source src={`${BASE_URL}/${prompt.video}`} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                )}
                            </Card>
                        </List.Item>
                    )}
                />
            </div>
        </div>
    );
};

export default UserInfo;
