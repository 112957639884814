import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'; // Використання для перенаправлення
import axios from 'axios';
import SiriWave from 'siriwave';
import './Ask.css';
import assistantImage from '../img/assistant.png';
import wolfImage from '../img/wolf.png';
import catImage from '../img/cat.png';
import BASE_URL from '../config';
import Sidebar from '../Sidebar/Sidebar'; // Імпортуй Sidebar
import './AskStyle.module.css';

const Ask = () => {
  const [age, setAge] = useState('7-12');
  const [voice, setVoice] = useState('Mike');
  const [style, setStyle] = useState('3D');
  const [question, setQuestion] = useState('Who is Eminem?');
  const [isRecognizing, setIsRecognizing] = useState(false);
  const [recognition, setRecognition] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isHistory, setIsHistory] = useState(false); // Стан для чекбокса History
  const siriRef = useRef(null);
  let siriWaveInstance = useRef(null);

  const navigate = useNavigate();
useEffect(() => {
    if ('webkitSpeechRecognition' in window || 'SpeechRecognition' in window) {
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        const recognitionInstance = new SpeechRecognition();
        recognitionInstance.continuous = false;
        recognitionInstance.interimResults = false;
        recognitionInstance.lang = 'uk-UA';
        setRecognition(recognitionInstance);
        console.log('SpeechRecognition initialized');
    } else {
        console.error('Speech Recognition is not supported in your browser.');
    }
}, []);

useEffect(() => {
    if (siriRef.current) {
        console.log('Container found:', siriRef.current);
    } else {
        console.error('Container not found');
    }
}, [siriRef]);

  // Ініціалізація SiriWave та розпізнавання мови
useEffect(() => {
    if (siriRef.current) {
        try {
            siriWaveInstance.current = new SiriWave({
                container: siriRef.current,
                width: 400,
                height: 120,
                style: 'ios9',
                autostart: true,
                amplitude: 0,
                speed: 0.4,
            });
            console.log('SiriWave initialized');
        } catch (error) {
            console.error('Error initializing SiriWave:', error);
        }
    } else {
        console.error('siriRef.current is null');
    }

    return () => {
        if (siriWaveInstance.current) {
            siriWaveInstance.current.dispose();
        }
    };
}, []);

  // Старт та стоп анімації SiriWave
const startWave = () => {
    if (siriWaveInstance.current) {
        console.log('Wave animation starting');
        siriWaveInstance.current.setAmplitude(2);
    }
};

const stopWave = () => {
    if (siriWaveInstance.current) {
        console.log('Wave animation stopping');
        siriWaveInstance.current.setAmplitude(0);
    }
};

  const startRecognition = () => {
    console.log('Recognition starting');
    if (recognition) {
      console.log('Recognition started');
      recognition.start();
      setIsRecognizing(true);
      startWave(); // Запуск хвильової анімації при старті
      recognition.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        setQuestion(transcript);
        setIsRecognizing(false);
        stopWave(); // Зупинка анімації після завершення розпізнавання
      };
      recognition.onerror = (event) => {
        console.error(event.error);
        setIsRecognizing(false);
        stopWave(); // Зупинка анімації при помилці
      };
    }
  };

  const stopRecognition = () => {
    console.log("Recognition stopped");
    if (recognition) {
      console.log('Recognition stopped');
      recognition.stop();
      setIsRecognizing(false);
      stopWave(); // Зупинка анімації вручну
    }
  };

const handleSendClick = async () => {
  if (question.trim()) {
    const token = localStorage.getItem('token');
    setIsLoading(true);
    try {
      const response = await axios.post(`${BASE_URL}/api/save-prompt/`, {
        text: question,
        type: isHistory ? 'History' : 'default', // Відправляємо 'History' якщо вибрано, або 'default'
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.status === 201) {
        console.log('Prompt saved successfully!');
      }
    } catch (error) {
      console.error('Error saving prompt:', error);
    } finally {
      setIsLoading(false);
    }
  } else {
    console.error('There is no text to send.');
  }

  navigate('/home');
};

  return (
    <div className="generation-container" style={{ background: 'linear-gradient(rgb(247, 149, 2) 0%, rgb(173, 104, 1) 100%)' }}>
      <Sidebar /> {/* Використовуй Sidebar як окремий компонент */}
      <main className="main-content">
        <div className="info_age">
          <div className="options">
            <div className="option-group">
              <span>AGE</span>
              <div className="button-group">
                <button className={age === '3-5' ? 'active' : ''} onClick={() => setAge('3-5')}>3-5</button>
                <button className={age === '5-7' ? 'active' : ''} onClick={() => setAge('5-7')}>5-7</button>
                <button className={age === '7-12' ? 'active' : ''} onClick={() => setAge('7-12')}>7-12</button>
              </div>
            </div>
            <div className="option-group">
              <span>VOICE</span>
              <div className="button-group">
                <button className={voice === 'Piki' ? 'active' : ''} onClick={() => setVoice('Piki')}>Piki</button>
                <button className={voice === 'Lisa' ? 'active' : ''} onClick={() => setVoice('Lisa')}>Lisa</button>
                <button className={voice === 'Mike' ? 'active' : ''} onClick={() => setVoice('Mike')}>Mike</button>
              </div>
            </div>
            <div className="option-group">
              <span>STYLE</span>
              <div className="button-group">
                <button className={style === '3D' ? 'active' : ''} onClick={() => setStyle('3D')}>3D</button>
                <button className={style === 'Cartoon' ? 'active' : ''} onClick={() => setStyle('Cartoon')}>Cartoon
                </button>
                <button className={style === 'Real' ? 'active' : ''} onClick={() => setStyle('Real')}>Real</button>
              </div>
            </div>
            {/* Чекбокс для History */}
            <div className="option-group">
              <label>
                <input
                    type="checkbox"
                    checked={isHistory}
                    onChange={(e) => setIsHistory(e.target.checked)} // Оновлюємо стан
                />
                History
              </label>
            </div>
          </div>

          <div className="assistant">
            <img src={assistantImage} alt="Assistant" className="assistant-image"/>
            <div className="speech-bubble">
              HI<br/>ONLY TWO STEPS!
            </div>
          </div>
        </div>

        <div className='runs'>
          <div className="question-input">
            <div className="loading-container">
              <div ref={siriRef} className="siri-wave-container"></div>
            </div>
            <p>2. SPEAKING OR WRITE YOUR QUESTION</p>
            <div className='input-wrapper'>
              <div className="input-container">
                <input
                    type="text"
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                />
                <div className="mic-button-container">
                  <button
                      className="mic-button"
                      onClick={isRecognizing ? stopRecognition : startRecognition}
                  >🎤
                  </button>
                  <button
                      className="search-button"
                      onClick={handleSendClick}
                      disabled={isLoading}
                  >🔍
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <div className="decorations">
        <img src={wolfImage} alt="Wolf" className="wolf-image"/>
        <img src={catImage} alt="Cat" className="cat-image" />
      </div>
    </div>
  );
};

export default Ask;
