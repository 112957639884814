import React from 'react';
import { Form, Input, Button, Checkbox, message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { GoogleOutlined, FacebookFilled } from '@ant-design/icons';
import { useAuth } from '../AuthContext';
import './Register.css';
import logoImage from '../img/mahaon.png';

const Register = () => {
  const { register } = useAuth();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      const payload = {
        username: values.username,
        email: values.email,
        password: values.password,
      };

      await register(payload);
      message.success('Registration successful! Redirecting...');
      navigate('/generation');
    } catch (error) {
      message.error('Registration failed. Please try again.');
      console.error('Registration failed:', error);
    }
  };

  return (
    <div className="register-container">
      <div className="register-form-container">
        <div className="butterfly butterfly-1"></div>
        <div className="butterfly butterfly-2"></div>
        <img src={logoImage} alt="MAHAON" className="logot" />
        <h2>Sign Up</h2>
        <Form
          form={form}
          name="register"
          className="register-form"
          onFinish={onFinish}
          initialValues={{ remember: true }}
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input placeholder="Username" />
          </Form.Item>

          <Form.Item
            name="email"
            rules={[
              { required: true, message: 'Please input your email!' },
              { type: 'email', message: 'Please enter a valid email!' }
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            dependencies={['password']}
            rules={[
              { required: true, message: 'Please confirm your password!' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The two passwords do not match!'));
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm Password" />
          </Form.Item>

          <Form.Item
            name="agreement"
            valuePropName="checked"
            rules={[{ validator: (_, value) => value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')) }]}
          >
            <Checkbox>I agree to the Terms & Conditions</Checkbox>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="register-button">
              Create Account
            </Button>
          </Form.Item>
        </Form>

        <div className="social-login">
          <span>or continue with</span>
          <div className="social-buttons">
            <Button icon={<GoogleOutlined />}>Google</Button>
            <Button icon={<FacebookFilled />}>Facebook</Button>
          </div>
        </div>

        <div className="login-link">
          Already have an account? <Link to="/login">Sign in</Link>
        </div>
      </div>
    </div>
  );
};

export default Register;
